



























import { Vue, Component, Prop } from "vue-property-decorator";
import { AppModule } from "@/store/app";

@Component
export default class CategorySelect extends Vue {
  @Prop({ type: Number, default: null }) readonly value!: number;

  get categories() {
    return AppModule.eventsCategories;
  }

  get categoryId() {
    return this.value;
  }

  set categoryId(value) {
    this.$emit("input", value);
  }
}
